import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import Nouknow from "../assets/nouknow.png";
import useNouknowEligibility from "../hooks/common/useNouknowEligibility";

const TopNouknowCard = () => {
  const navigate = useNavigateWithUrl();
  const { isNouknowsEligible } = useNouknowEligibility();

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="start"
            align="start"
            flexShrink={0}
            direction="row"
            minHeight="65px"
          >
            <img
              src={Nouknow}
              alt="nouknow"
              className={`image--icon image--nouknow ${
                !isNouknowsEligible ? "image--icon--disabled" : ""
              }`}
            />
            <LayoutBox
              gap="1/2x"
              justify="center"
              align="start"
              flexShrink={0}
              direction="column"
            >
              <Text bold size="xl">
                のうKNOW®
              </Text>
              <Text size="large">オンライン認知機能検査</Text>
            </LayoutBox>
          </LayoutBox>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <Text size="large" color="neutralUserLight">
          3か月ごとに受検してください。
        </Text>
      }
      onClick={() => navigate(PATIENT.USC022)}
      clickable={isNouknowsEligible}
      disabled={!isNouknowsEligible}
    />
  );
};
export default TopNouknowCard;
