import React, { useEffect } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Button from "../components/Button/Button";
import Divider from "../components/Divider/Divider";
import SpButton from "../components/Button/SpButton";
import DatePicker from "../components/DatePicker/DatePicker";
import Badge from "../components/Badge/Badge";
import { useCheckErrorThrowError } from "../utils/checkError";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import SpDropdown from "../components/Dropdown/SpDropdown";
import useBookingDateFetch from "../hooks/common/useBookingDateFetch";
import PatientFooter from "../features/PatientFooter";
import useBookingTimeFetch from "../hooks/common/useBookingTimeFetch";
import { checkBookingRequireRules, hasDuplicateBookings } from "../utils/utils";
import useBookingApply from "../hooks/common/useBookingApply";
import { ERROR_MESSAGES } from "../constants/messages";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import useSettingInfoFetch from "../hooks/common/useSettingFetch";
import useInterviewTypeConditions from "../hooks/common/useInterviewTypeConditions";
import useGetFreeInterview from "../hooks/common/useGetFreeInterview";

type SavedFormData = {
  selectedDates: string[];
  selectedTimes: string[];
};

const USD001 = () => {
  const navigateWithBack = useNavigateWithBack(PATIENT.USD004);
  const [candidateCount, setCandidateCount] = React.useState(3);
  const [selectError, setSelectError] = React.useState<string[]>([]);
  const [interviewType] = useInterviewTypeConditions();

  // 日付選択
  const [selectedDates, setSelectedDates] = React.useState<
    Array<Date | undefined>
  >(Array.from({ length: candidateCount }).map(() => undefined));

  // 時間選択
  const [selectedTimes, setSelectedTimes] = React.useState<
    Array<string | undefined>
  >(Array.from({ length: candidateCount }).map(() => undefined));

  // 時間の選択肢
  const [timeOptions, setTimeOptions] = React.useState<
    Array<Array<{ value: string; label: string }>>
  >(Array.from({ length: candidateCount }).map(() => []));

  // 日時
  const { includeDates, error: bookingDateFetchError } = useBookingDateFetch(
    "interview",
    "",
  );

  // 時間
  const { error: bookingTimeFetchError } = useBookingTimeFetch(
    "interview",
    selectedDates,
    "",
    setTimeOptions,
  );

  const { ticketExpireStr, invitationTicketId, error } = useGetFreeInterview();

  const { settingsInfo, error: fetchSettingError } = useSettingInfoFetch();

  // 申し込み
  const { actionError: bookingApplyError, applyBooking } = useBookingApply();

  useCheckErrorThrowError([
    error,
    bookingDateFetchError,
    bookingTimeFetchError,
    bookingApplyError,
    fetchSettingError,
  ]);

  // 候補日追加時の状態反映
  useEffect(() => {
    setSelectError([]);
    setSelectedDates((currentSelectedDates) => {
      if (candidateCount > currentSelectedDates.length) {
        return [
          ...currentSelectedDates,
          ...Array.from({
            length: candidateCount - currentSelectedDates.length,
          }).map(() => undefined),
        ];
      }

      return currentSelectedDates;
    });
    setTimeOptions((currentSelectedDates) => {
      if (candidateCount > currentSelectedDates.length) {
        return [
          ...currentSelectedDates,
          ...Array.from({
            length: candidateCount - currentSelectedDates.length,
          }).map(() => []),
        ];
      }

      return currentSelectedDates;
    });
  }, [candidateCount]);

  // セッションストレージの値を復元
  useEffect(() => {
    const savedFormDataString = sessionStorage.getItem("interviewBookingInput");
    if (savedFormDataString) {
      const savedFormData = JSON.parse(savedFormDataString) as SavedFormData;

      // savedFormData.selectedDates から Date オブジェクトの配列を生成
      const dates = savedFormData.selectedDates.map((dateString) =>
        dateString ? new Date(dateString) : undefined,
      );

      setSelectedDates(dates);
      setSelectedTimes(savedFormData.selectedTimes);
      setCandidateCount(dates.length);
    }
  }, []);

  // 日付選択
  const handleChangeDate = React.useCallback(
    (index: number, date: Date) => {
      setSelectError([]);
      // 日付 セット
      const updatedDates = [...selectedDates];
      updatedDates[index] = date;
      setSelectedDates(updatedDates);
      // 時間 リセット
      const updatedTimes = [...selectedTimes];
      updatedTimes[index] = undefined;
      setSelectedTimes(updatedTimes);
    },
    [selectedDates, selectedTimes],
  );

  // 時間選択
  const handleChangeDropdown = (index: number, time: string) => {
    setSelectError([]);
    const updatedTimes = [...selectedTimes];
    updatedTimes[index] = time;
    setSelectedTimes(updatedTimes);
  };

  // 戻るボタン押下時
  const onBackBtnClicked = () => {
    navigateWithBack();
  };

  // 有料 申し込みボタン押下
  const handlePaidApplication = async () => {
    setSelectError([]);

    // 必須チェック
    const isRequire = checkBookingRequireRules(
      selectedDates,
      selectedTimes,
      candidateCount,
    );
    if (!isRequire) {
      setSelectError([ERROR_MESSAGES.REQUIRED_SELECTION]);

      return;
    }

    // 重複チェック
    const hasDuplicate = hasDuplicateBookings(selectedDates, selectedTimes);
    if (hasDuplicate) {
      setSelectError([ERROR_MESSAGES.DUPLICATE_BOOKING]);

      return;
    }

    // セッションストレージに保存
    sessionStorage.setItem(
      "interviewBookingInput",
      JSON.stringify({
        selectedDates,
        selectedTimes,
      }),
    );

    // ストライプURL取得
    const stripeUrl = await applyBooking(
      "interview",
      "",
      selectedDates,
      selectedTimes,
    );
    if (stripeUrl) {
      window.location.href = stripeUrl;
    }
  };

  // 無料 申し込みボタン押下
  const handleFreeApplication = async () => {
    setSelectError([]);

    // 必須チェック
    const isRequire = checkBookingRequireRules(
      selectedDates,
      selectedTimes,
      candidateCount,
    );
    if (!isRequire) {
      setSelectError([ERROR_MESSAGES.REQUIRED_SELECTION]);

      return;
    }

    // 重複チェック
    const hasDuplicate = hasDuplicateBookings(selectedDates, selectedTimes);
    if (hasDuplicate) {
      setSelectError([ERROR_MESSAGES.DUPLICATE_BOOKING]);

      return;
    }

    await applyBooking(
      "interview",
      "",
      selectedDates,
      selectedTimes,
      undefined,
      undefined,
      invitationTicketId,
    );
  };

  // 候補日削除
  const handleRemoveCandidate = (index: number) => {
    setSelectedDates((currentSelectedDates) => {
      const updatedDates = currentSelectedDates.filter((_, i) => i !== index);

      return updatedDates;
    });

    setSelectedTimes((currentSelectedTimes) => {
      const updatedTimes = currentSelectedTimes.filter((_, i) => i !== index);

      return updatedTimes;
    });

    setTimeOptions((currentOptions) => {
      const updatedOptions = currentOptions.filter((_, i) => i !== index);

      return updatedOptions;
    });

    setCandidateCount((prevCount) => prevCount - 1);
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          selectedTab={SELECTED_TAB_NUM.healthAdvice}
          minWidth="1076px"
        />
        <LayoutBox
          fullWidth
          justify="center"
          align="center"
          gap="3x"
          direction="column"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="オンライン面談"
            headerNote={
              interviewType === "0" ? "（MRI検査付帯）" : "（別途料金）"
            }
            content="オンライン会議システム（Zoom）を利用して医師面談ができます"
            borderBottom="primary"
            onClickBack={onBackBtnClicked}
          />
          <Sheet padding="0px 108px 40px 108px">
            <LayoutBox
              direction="column"
              gap="3x"
              justify="center"
              align="center"
              maxWidth="440px"
            >
              <Sheet type="card" padding="24px 16px">
                <LayoutBox
                  direction="column"
                  justify="center"
                  align="center"
                  gap="3x"
                >
                  <Text size="xl">
                    ご希望の開始時間帯を3つ以上ご指定のうえ、お申し込みください。
                  </Text>
                  <Sheet
                    color="primary-lightest"
                    padding="16px"
                    borderRadius="8px"
                  >
                    <LayoutBox
                      gap="1x"
                      fullWidth
                      align="center"
                      justify="between"
                    >
                      <Text bold size="large" color="neutralUserBlack">
                        所要時間
                      </Text>
                      <LayoutBox align="center" gap="1x">
                        <Text size="xl" color="primary" bold>
                          {settingsInfo?.interview_interval}
                        </Text>
                        <Text size="large" color="primary">
                          分
                        </Text>
                      </LayoutBox>
                    </LayoutBox>
                  </Sheet>
                  {Array.from({ length: candidateCount }, (_, index) => (
                    <LayoutBox
                      fullWidth
                      gap="1x"
                      direction="column"
                      key={index}
                    >
                      <LayoutBox align="center" gap="1x">
                        <Text bold size="xl">
                          第{index + 1}候補
                        </Text>
                        {index <= 2 ? (
                          <Badge color="red">必須</Badge>
                        ) : (
                          <Button
                            type="secondary"
                            size="small"
                            width="40px"
                            height="40px"
                            shape="circle"
                            icon="close"
                            onClick={() => handleRemoveCandidate(index)}
                          />
                        )}
                      </LayoutBox>
                      <Text size="large" bold color="neutralUserLight">
                        日付
                      </Text>
                      <DatePicker
                        width="100%"
                        selectedDate={selectedDates[index]}
                        includeDates={includeDates}
                        onChangeDate={(newDate) =>
                          handleChangeDate(index, newDate)
                        }
                      />
                      <Text size="large" bold color="neutralUserLight">
                        開始時間帯
                      </Text>
                      <LayoutBox
                        direction="row"
                        fullWidth
                        align="center"
                        gap="1x"
                      >
                        <SpDropdown
                          items={timeOptions[index] || []}
                          disabled={!selectedDates[index]}
                          value={selectedTimes[index]}
                          onChange={(time) => handleChangeDropdown(index, time)}
                          width="70%"
                        />
                        <Text size="xl">の間に来院</Text>
                      </LayoutBox>
                    </LayoutBox>
                  ))}
                  <LayoutBox justify="start" fullWidth>
                    <Button
                      size="small"
                      type="secondary"
                      icon="add"
                      iconPosition="left"
                      shape="circle"
                      width="200px"
                      height="40px"
                      disabled={candidateCount >= 5}
                      onClick={() =>
                        setCandidateCount((prevCount) => prevCount + 1)
                      }
                    >
                      候補日を追加
                    </Button>
                  </LayoutBox>
                  <Divider darkColor margin="0" />
                  {selectError.map((error, index) => (
                    <div
                      key={index}
                      className="sp-form-set__caption-massage--error"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {error}
                    </div>
                  ))}
                  {interviewType === "0" && (
                    <Sheet
                      padding="8px 0 0 0"
                      spPadding={false}
                      type="border-blue"
                      withBorderRadius
                    >
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <LayoutBox
                          direction="column"
                          justify="center"
                          align="center"
                          fullWidth
                          gap="1/2x"
                        >
                          <Text size="large" color="darkBlue" bold>
                            MRI検査をご受検の方限定！
                          </Text>
                          {ticketExpireStr && (
                            <Text size="base" color="neutralLight">
                              期限：{ticketExpireStr}まで
                            </Text>
                          )}
                        </LayoutBox>
                        <Sheet
                          withBorderRadius
                          borderRadius="0 0 8px 8px"
                          color="blue"
                          padding="16px"
                        >
                          <LayoutBox
                            gap="1x"
                            justify="center"
                            align="center"
                            direction="column"
                            fullWidth
                          >
                            <LayoutBox
                              fullWidth
                              maxWidth="279px"
                              justify="between"
                            >
                              <Text size="large">面談1回</Text>
                              <LayoutBox>
                                <Text size="xl" bold color="darkBlue">
                                  無料
                                </Text>
                              </LayoutBox>
                            </LayoutBox>
                            <SpButton
                              type="blue"
                              width="279px"
                              size="small"
                              withArrowRight
                              onClick={() => {
                                void handleFreeApplication();
                              }}
                            >
                              申し込む
                            </SpButton>
                          </LayoutBox>
                        </Sheet>
                      </LayoutBox>
                    </Sheet>
                  )}
                  {interviewType === "1" && (
                    <Sheet withBorderRadius padding="16px" color="lightest">
                      <LayoutBox
                        direction="column"
                        align="center"
                        gap="1x"
                        fullWidth
                      >
                        <LayoutBox fullWidth maxWidth="279px" justify="between">
                          <Text size="large">面談1回</Text>
                          <LayoutBox gap="1/2x" align="center">
                            <Text size="xl" bold color="orange">
                              1,000
                            </Text>
                            <Text size="large" color="orange">
                              円(税込)
                            </Text>
                          </LayoutBox>
                        </LayoutBox>
                        <SpButton
                          width="279px"
                          size="small"
                          withArrowRight
                          onClick={() => {
                            void handlePaidApplication();
                          }}
                        >
                          申し込む
                        </SpButton>
                      </LayoutBox>
                    </Sheet>
                  )}
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USD001;
