import React, { useEffect, useState } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Input from "../components/Input/Input";
import SpButton from "../components/Button/SpButton";
import SpFormSet from "../components/FormSet/SpFormSet";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import SpDropdown from "../components/Dropdown/SpDropdown";
import BulletList from "../components/List/BulletList";
import StepBar from "../components/StepBar/StepBar";
import CheckboxGroup from "../components/Checkbox/CheckboxGroup";
import TermsOfServiceCheckBox, {
  termsOfServiceCheckBoxItems,
} from "../components/Checkbox/TermsOfServiceCheckBox";
import usePolicyChecks from "../hooks/common/usePolicyChecks";
import {
  BULLET_LIST_ITEMS,
  DROPDOWN_MONTH_OPTIONS,
  DROPDOWN_YEAR_OPTIONS,
  FORMAT_STYLE,
  PREFECTURE_OPTIONS,
  RADIO_GENDER_ITEMS,
  RADIO_WITH_CODE_ITEMS,
  REGISTER_STEP_ITEMS,
  UserStatusID,
} from "../constants/common";
import PatientFooter from "../features/PatientFooter";
import useUserRegisterInput, {
  setNameSei,
  setNameMei,
  setNameSeiKana,
  setNameMeiKana,
  setGender,
  setBirthYear,
  setBirthMonth,
  setBirthDay,
  setTel,
  // setSelectedNotice,
  setSelectedWithCode,
  setZipcode,
  setPref,
  setCity,
  setTown1,
  setTown2,
  setEmail,
  checkUserRegisterInputError,
  setEnabledModality,
  setExistingEmailError,
  setCampaignCode,
  setCampaignError,
  setCampaignLimitError,
  setCampaignCancelError,
  setPastDiagnosis,
} from "../hooks/input/useUserRegisterInput";
import useUsa004DBActions from "../hooks/pages/USA004/useUsa004DBActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import useCheckUsersEmail from "../hooks/common/useCheckUsersEmail";
import { generateDays, formatDate } from "../utils/utils";

const USA004 = () => {
  const [dayOptions, setDayOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [isInitial, setIsInitial] = React.useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState(termsOfServiceCheckBoxItems);
  const [policyChecks] = usePolicyChecks();
  const navigate = useNavigateWithUrl();
  const {
    result,
    invitation,
    error,
    fetchAddress,
    fetchCampaign,
    fetchCampaignTerm,
    campaignError,
  } = useUsa004DBActions();
  const { checkExistingEmail, error: checkEmailError } = useCheckUsersEmail();
  const isEmailDisabled = !(
    result?.line_id ||
    result?.google_id ||
    result?.yahoo_id
  );
  const isWrongStatus = result?.status !== UserStatusID.INVITATION;
  const hasUncheckedItems = checkedItems.some(
    (checkedItem) => !checkedItem.checked,
  );

  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      tel,
      zipcode,
      pref,
      city,
      town1,
      town2,
      enabledModality,
      pastDiagnosis,
      // selectedNotice,
      selectedWithCode,
      email,
      campaignCode,
    },
  ] = useUserRegisterInput();

  useCheckErrorThrowError([error, checkEmailError, campaignError]);

  useEffect(() => {
    if (policyChecks.length > 0) {
      setCheckedItems((prevCheckedItems) => {
        const updatedCheckboxItems = prevCheckedItems.map((item, index) => {
          const check = policyChecks[index];

          return index < policyChecks.length
            ? { ...item, checked: check }
            : item;
        });

        return updatedCheckboxItems;
      });
    }
  }, [policyChecks, setCheckedItems]);

  useEffect(() => {
    if (!email.value && result?.email) {
      setEmail(result.email);
      setIsInitial(
        result?.status === UserStatusID.ACTIVE ||
          result?.status === UserStatusID.INVITATION,
      );
    }
  }, [email.value, result]);

  useEffect(() => {
    if (isInitial) {
      if (!nameSei.value) {
        setNameSei(result?.name_sei || "");
      }
      if (!nameMei.value) {
        setNameMei(result?.name_mei || "");
      }
      if (!nameSeiKana.value) {
        setNameSeiKana(result?.name_sei_kana || "");
      }
      if (!nameMeiKana.value) {
        setNameMeiKana(result?.name_mei_kana || "");
      }
      if (!gender.value) {
        let convertGender = "";

        if (result?.gender === "1" || result?.gender === "2") {
          convertGender = result.gender;
        } else {
          switch (result?.gender) {
            case "male":
              convertGender = "1";
              break;
            case "female":
              convertGender = "2";
              break;
            default:
              convertGender = "";
          }
        }

        setGender(convertGender || "");
      }
      if (
        !birthYear.value ||
        birthYear.value === "1960" ||
        birthYear.value === "undefined"
      ) {
        setBirthYear(String(result?.birth_year) || "");
      }
      if (!birthMonth.value || birthMonth.value === "undefined") {
        setBirthMonth(String(result?.birth_month) || "");
      }
      if (!birthDay.value || birthDay.value === "undefined") {
        setBirthDay(String(result?.birth_day) || "");
      }
      if (!tel.value) {
        setTel(result?.tel || "");
      }
      if (result?.status === UserStatusID.INVITATION) {
        if (!campaignCode.value && selectedWithCode.value === "2") {
          setSelectedWithCode(result?.campaign_code ? "1" : "2");
          setCampaignCode(result?.campaign_code || "");
        }
      } else {
        setSelectedWithCode("2");
        setCampaignCode("");
      }

      if (!zipcode.value) {
        setZipcode(result?.zipcode || "");
      }
      if (!pref.value) {
        setPref(result?.pref || "");
      }
      if (!city.value) {
        setCity(result?.city || "");
      }
      if (!town1.value) {
        setTown1(result?.town1 || "");
      }
      if (!town2) {
        setTown2(result?.town2 || "");
      }
      setIsInitial(false);
    }
  }, [
    birthDay.value,
    birthMonth.value,
    birthYear.value,
    campaignCode.value,
    city.value,
    email.value,
    gender.value,
    isInitial,
    nameMei.value,
    nameMeiKana.value,
    nameSei.value,
    nameSeiKana.value,
    pref.value,
    result,
    selectedWithCode.value,
    tel.value,
    town1.value,
    town2,
    zipcode.value,
  ]);

  useEffect(() => {
    if (birthYear && birthMonth) {
      const validDays = generateDays(birthYear.value, birthMonth.value);
      setDayOptions(validDays.map((day) => ({ label: `${day}`, value: day })));

      // 既に選択されている日が有効な日より多い場合はリセット
      if (birthDay && Number(birthDay.value) > validDays.length) {
        setBirthDay("");
      }
    } else {
      // 年と月が選択されていない場合は日をリセット
      setDayOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthYear, birthMonth]);

  // 受検条件チェックボックス
  const handleEnabledModalityChange = (value: string) => {
    const updatedCheckboxItems = enabledModality.map((item) =>
      item.value === value ? { ...item, checked: !item.checked } : item,
    );

    setEnabledModality(updatedCheckboxItems);
  };

  // 過去の診断チェックボックス
  const handlePastDiagnosisChange = (value: string) => {
    const updatedCheckboxItems = pastDiagnosis.map((item) =>
      item.value === value ? { ...item, checked: !item.checked } : item,
    );

    setPastDiagnosis(updatedCheckboxItems);
  };

  // 入力内容確認ボタン押下
  const navigateToConfirm = () => {
    void (async () => {
      // メール重複チェック
      const isExistingEmailError = await checkExistingEmail(email.value);
      if (isExistingEmailError) {
        setExistingEmailError();

        return;
      }

      // キャンペーンコードなしを選択時クリア
      if (selectedWithCode.value === "2") {
        setCampaignCode("");
      }

      // キャンペーンコードチェック
      const campaignResult = await fetchCampaign();
      const isCampaign = campaignCode.value && !campaignResult;
      if (isCampaign) {
        setCampaignError();

        return;
      }
      if (campaignResult) {
        // キャンペーンコード人数チェック
        const campaignTermCount = await fetchCampaignTerm(campaignResult._id);
        const count = campaignTermCount?.length || 0;
        const isCampaignLimit = campaignResult.limit <= count;
        if (isCampaignLimit) {
          setCampaignLimitError();

          return;
        }

        // キャンペーンコード期限チェック
        const cancelPeriod = formatDate(
          campaignResult?.cancel_period,
          FORMAT_STYLE["YYYY-MM-DD"],
        );
        const today = formatDate(new Date(), FORMAT_STYLE["YYYY-MM-DD"]);
        const isCampaignTerm = cancelPeriod < today;

        if (isCampaignTerm) {
          setCampaignCancelError();

          return;
        }
      }

      navigate(PATIENT.USA006);
    })();
  };

  // 住所検索
  const handleFetchAddress = () => {
    fetchAddress();
  };

  const handleGoBack = () => navigate(PATIENT.USA002);

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner area-with-header-footer">
          {!invitation && (
            <StepBar currentStepId="1" steps={REGISTER_STEP_ITEMS} />
          )}
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="お客様情報の入力"
            withButton={false}
            borderBottom="primary"
          />
          <Sheet padding="40px 0" className="util-sp-py-24">
            <LayoutBox direction="column" maxWidth="1063px" fullWidth gap="2x">
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" fullWidth gap="3x">
                  <Title
                    type="h2"
                    header="会員情報"
                    withButton={false}
                    borderBottom="primaryLight"
                  />

                  <SpFormSet
                    label="お名前"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                  >
                    <LayoutBox
                      gap="1x"
                      direction="column"
                      className="util-py-8"
                      minWidth="400px"
                    >
                      <LayoutBox gap="1x" fullWidth>
                        <SpFormSet
                          label="姓"
                          vertical
                          errorTextList={[nameSei.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameSei.value}
                            onChange={(value) => setNameSei(value)}
                            error={nameSei.isError}
                          />
                        </SpFormSet>
                        <SpFormSet
                          label="名"
                          vertical
                          errorTextList={[nameMei.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameMei.value}
                            onChange={(value) => setNameMei(value)}
                            error={nameMei.isError}
                          />
                        </SpFormSet>
                      </LayoutBox>
                      <LayoutBox gap="1x" fullWidth>
                        <SpFormSet
                          label="セイ"
                          vertical
                          errorTextList={[nameSeiKana.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameSeiKana.value}
                            onChange={(value) => setNameSeiKana(value)}
                            error={nameSeiKana.isError}
                          />
                        </SpFormSet>
                        <SpFormSet
                          label="メイ"
                          vertical
                          errorTextList={[nameMeiKana.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameMeiKana.value}
                            onChange={(value) => setNameMeiKana(value)}
                            error={nameMeiKana.isError}
                          />
                        </SpFormSet>
                      </LayoutBox>
                    </LayoutBox>
                  </SpFormSet>
                  <SpFormSet
                    label="性別"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                    errorTextList={[gender.validationMessage]}
                  >
                    <LayoutBox minWidth="400px" className="util-sp-full-width">
                      <RadioButtonGroup
                        name="RadioGender"
                        items={RADIO_GENDER_ITEMS}
                        onChange={(value) => setGender(value)}
                        selectedValue={gender.value}
                        withBorder
                        width="100%"
                        column={2}
                        radioGap="8px"
                      />
                    </LayoutBox>
                  </SpFormSet>
                  {result?.status !== UserStatusID.CANCELLATION && (
                    <SpFormSet
                      label="生年月日"
                      base
                      required="badge"
                      labelSize="medium"
                      labelWidth="200px"
                      spToColumnAndFullWidth
                      errorTextList={[
                        birthYear.validationMessage,
                        birthMonth.validationMessage,
                        birthDay.validationMessage,
                      ]}
                    >
                      <LayoutBox gap="1x" direction="column" fullWidth>
                        <LayoutBox gap="1x" fullWidth align="center">
                          <SpDropdown
                            placeholder="XXXX（昭和37）"
                            items={DROPDOWN_YEAR_OPTIONS}
                            onChange={(value) => setBirthYear(value)}
                            width="256px"
                            value={birthYear.value}
                          />
                          <Text size="xl" color="neutralUserBlack">
                            年
                          </Text>
                        </LayoutBox>
                        <LayoutBox gap="1x" fullWidth>
                          <LayoutBox gap="1x" align="center">
                            <SpDropdown
                              placeholder="MM"
                              items={DROPDOWN_MONTH_OPTIONS}
                              onChange={(value) => setBirthMonth(value)}
                              width="97px"
                              value={String(birthMonth.value)}
                            />
                            <Text
                              size="xl"
                              color="neutralUserBlack"
                              width="45px"
                            >
                              月
                            </Text>
                          </LayoutBox>
                          <LayoutBox gap="1x" align="center">
                            <SpDropdown
                              placeholder="DD"
                              items={dayOptions}
                              onChange={(value) => setBirthDay(value)}
                              width="97px"
                              value={String(birthDay.value)}
                            />
                            <Text
                              size="xl"
                              color="neutralUserBlack"
                              width="45px"
                            >
                              日
                            </Text>
                          </LayoutBox>
                        </LayoutBox>
                      </LayoutBox>
                    </SpFormSet>
                  )}
                  <SpFormSet
                    label="電話番号"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                    errorTextList={[tel.validationMessage]}
                  >
                    <Input
                      value={tel.value}
                      inputMode="tel"
                      width="335px"
                      onChange={(value) => setTel(value)}
                      error={tel.isError}
                    />
                  </SpFormSet>
                  <SpFormSet
                    label="住所"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                  >
                    <LayoutBox gap="1x" direction="column" fullWidth>
                      <SpFormSet
                        label="郵便番号"
                        vertical
                        required="icon"
                        errorTextList={[zipcode.validationMessage]}
                      >
                        <LayoutBox fullWidth gap="1x">
                          <Input
                            width="151.5px"
                            inputMode="numeric"
                            value={zipcode.value}
                            onChange={(value) => setZipcode(value)}
                            error={zipcode.isError}
                          />

                          <SpButton
                            type="secondary"
                            width="99px"
                            fontSize="medium"
                            onClick={handleFetchAddress}
                          >
                            住所検索
                          </SpButton>
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="都道府県"
                        vertical
                        required="icon"
                        errorTextList={[pref.validationMessage]}
                      >
                        <SpDropdown
                          placeholder=""
                          items={PREFECTURE_OPTIONS}
                          onChange={(value) => setPref(value)}
                          width="151.5px"
                          value={pref.value}
                        />
                      </SpFormSet>
                      <SpFormSet
                        label="市区町村"
                        vertical
                        required="icon"
                        errorTextList={[city.validationMessage]}
                      >
                        <Input
                          width="100%"
                          value={city.value}
                          onChange={(value) => setCity(value)}
                          error={city.isError}
                        />
                      </SpFormSet>
                      <SpFormSet
                        label="町域・番地"
                        vertical
                        required="icon"
                        errorTextList={[town1.validationMessage]}
                      >
                        <Input
                          width="100%"
                          value={town1.value}
                          onChange={(value) => setTown1(value)}
                          error={town1.isError}
                        />
                      </SpFormSet>
                      <SpFormSet label="建物名など" vertical>
                        <Input
                          width="100%"
                          value={town2}
                          onChange={(value) => setTown2(value)}
                        />
                      </SpFormSet>
                    </LayoutBox>
                  </SpFormSet>
                  {result?.status !== UserStatusID.CANCELLATION && (
                    <SpFormSet
                      label="メールアドレス"
                      base
                      required="badge"
                      labelSize="medium"
                      labelWidth="200px"
                      spToColumnAndFullWidth
                      errorTextList={[email.validationMessage]}
                    >
                      <Input
                        value={email.value}
                        width="335px"
                        onChange={(value) => setEmail(value)}
                        error={email.isError}
                        disabled={isEmailDisabled}
                      />
                    </SpFormSet>
                  )}
                </LayoutBox>
              </Sheet>
              <Sheet
                type="card"
                padding="16px 32px 32px 32px"
                maxWidth="1064px"
                width="100%"
              >
                <LayoutBox direction="column" gap="3x" fullWidth>
                  <Title
                    header="過去の診断について"
                    borderBottom="primaryLight"
                    withButton={false}
                    type="h2"
                  />
                  <Text size="xl" color="neutralUserBlack">
                    本サービスは、認知症 もしくはMCI（軽度認知障害）
                    と診断されたことがない方を対象とします。
                    <br />
                    これまでに該当する診断をされていないことを確認してください。
                  </Text>
                  <CheckboxGroup
                    items={pastDiagnosis}
                    withContainer
                    onChecked={(value) => handlePastDiagnosisChange(value)}
                  />
                </LayoutBox>
              </Sheet>
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" fullWidth gap="3x">
                  <Title
                    header="MRI検査の禁忌事項について"
                    borderBottom="primaryLight"
                    withButton={false}
                    type="h2"
                  />
                  <LayoutBox direction="column" gap="2x" fullWidth>
                    <Text size="xl" color="error">
                      本サービスにはMRI検査が含まれるため、MRI検査を受検できない方は会員登録することがきません。
                      <br />
                      以下に当てはまる項目がないことをご確認ください。
                    </Text>
                    <Sheet type="border-red" borderRadius="8px" padding="16px">
                      <BulletList
                        title="体内に下記の医療機器がある方"
                        items={BULLET_LIST_ITEMS}
                        color="red"
                      />
                    </Sheet>
                  </LayoutBox>
                  <CheckboxGroup
                    items={enabledModality}
                    withContainer
                    onChecked={(value) => handleEnabledModalityChange(value)}
                  />
                </LayoutBox>
              </Sheet>
              {/* FIXME: 仕様未決定のため、一旦非表示 */}
              {/* <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" fullWidth gap="3x">
                  <Title
                    type="h2"
                    header="お知らせ"
                    withButton={false}
                    borderBottom="primaryLight"
                  />
                  <LayoutBox fullWidth>
                    <SpFormSet
                      errorTextList={
                        selectedNotice.validationMessage
                          ? ["お知らせが選択されていません。"]
                          : []
                      }
                    >
                      <RadioButtonGroup
                        name="RadioNotice"
                        items={RADIO_NOTICE_ITEMS}
                        onChange={(value) => setSelectedNotice(value)}
                        selectedValue={selectedNotice.value}
                        withBorder
                        width="100%"
                        column={2}
                        radioGap="8px"
                      />
                    </SpFormSet>
                  </LayoutBox>
                </LayoutBox>
              </Sheet> */}
              {!invitation && (
                <Sheet type="card" padding="16px 16px 32px 16px">
                  <LayoutBox direction="column" fullWidth gap="3x">
                    <Title
                      type="h2"
                      header="お支払い方法"
                      withButton={false}
                      borderBottom="primaryLight"
                    />
                    <SpFormSet
                      label="キャンペーンコード"
                      base
                      required="badge"
                      labelSize="medium"
                      labelWidth="200px"
                      spToColumnAndFullWidth
                    >
                      <LayoutBox fullWidth>
                        <RadioButtonGroup
                          name="RadioWithCode"
                          items={RADIO_WITH_CODE_ITEMS}
                          onChange={(value) => {
                            setSelectedWithCode(value);
                          }}
                          selectedValue={selectedWithCode.value}
                          withBorder
                          width="100%"
                          column={2}
                          radioGap="8px"
                        />
                      </LayoutBox>
                    </SpFormSet>
                    {selectedWithCode.value === "1" && (
                      <SpFormSet
                        label="キャンペーンコード"
                        vertical
                        errorTextList={[campaignCode.validationMessage]}
                      >
                        <Input
                          width="100%"
                          value={campaignCode.value}
                          onChange={(value) => setCampaignCode(value)}
                          error={campaignCode.isError}
                        />
                      </SpFormSet>
                    )}
                  </LayoutBox>
                </Sheet>
              )}
              {isWrongStatus ? (
                <LayoutBox fullWidth>
                  <TermsOfServiceCheckBox
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                  />
                </LayoutBox>
              ) : null}
              {!invitation ? (
                <LayoutBox
                  justify="between"
                  gap="2x"
                  className="util-sp-full-width util-mx-auto"
                  wrapReverse
                  width="1064px"
                >
                  <SpButton
                    type="secondary"
                    width="400px"
                    size="medium"
                    withArrowLeft
                    mobileFullWidth
                    onClick={handleGoBack}
                  >
                    戻る
                  </SpButton>
                  <SpButton
                    type="primary"
                    width="400px"
                    size="medium"
                    withArrowRight
                    mobileFullWidth
                    onClick={navigateToConfirm}
                    disabled={
                      checkUserRegisterInputError() ||
                      (isWrongStatus ? hasUncheckedItems : false)
                    }
                  >
                    入力内容の確認
                  </SpButton>
                </LayoutBox>
              ) : (
                <LayoutBox fullWidth justify="end">
                  <SpButton
                    width="400px"
                    buttonType="submit"
                    withArrowRight
                    size="medium"
                    onClick={navigateToConfirm}
                    disabled={
                      checkUserRegisterInputError() ||
                      (isWrongStatus ? hasUncheckedItems : false)
                    }
                  >
                    入力内容の確認
                  </SpButton>
                </LayoutBox>
              )}
            </LayoutBox>
          </Sheet>
          <div />
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USA004;
