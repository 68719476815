import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Card from "../components/Card/Card";
import Title from "../components/Title/Title";
import PatientFooter from "../features/PatientFooter";
import { PATIENT } from "../constants/pagePaths";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import useUsc011Conditions from "../hooks/pages/USC011/useUsc011Conditions";
import usePatientModalityFetchById from "../hooks/common/usePatientModalityFetchById";
import { useCheckErrorThrowError } from "../utils/checkError";
import useSignedMriUrl from "../hooks/common/useSignedMriUrl";

const USC011 = () => {
  const navigate = useNavigate();
  const [{ selectMriId }] = useUsc011Conditions();

  const { patientModality, error } = usePatientModalityFetchById(selectMriId);
  const { fetchSignedMriUrl, signedMriUrlError } = useSignedMriUrl();

  useCheckErrorThrowError([error, signedMriUrlError]);

  const handleClickBack = useCallback(
    () => navigate(PATIENT.USC026),
    [navigate],
  );

  const handleOpenReport = async () => {
    const signedUrl = await fetchSignedMriUrl(selectMriId);
    if (signedUrl) {
      window.open(signedUrl, "_blank");
    }
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="結果"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <Title
                type="h2"
                header="MRI検査"
                subheader={`受検日: ${patientModality.fix_book_date_start}`}
                borderBottom="primaryLight"
                withButton={false}
              />
              <Card
                type="button"
                buttonItem={{
                  label: "レポート（PDF）",
                  width: "311px",
                  size: "medium",
                }}
                content={
                  <Text size="xl">{patientModality.interpretation_note}</Text>
                }
                onButtonItemClick={() => {
                  void handleOpenReport();
                }}
              />
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC011;
