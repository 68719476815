import Card from "../components/Card/Card";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";

const PetCardWithdrawal = () => {
  const navigate = useNavigateWithUrl();

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="start"
            align="start"
            flexShrink={0}
            direction="row"
            minHeight="65px"
          >
            <LayoutBox
              gap="1/2x"
              justify="center"
              align="start"
              flexShrink={0}
              direction="column"
            >
              <Text bold size="xl">
                FDG-PET検査(別途料金)
              </Text>
            </LayoutBox>
          </LayoutBox>
        </>
      }
      onClick={() => navigate(PATIENT.USC026)}
    />
  );
};
export default PetCardWithdrawal;
