import Card from "../components/Card/Card";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import BrainSuite from "../assets/brain_suite.png";

const MriCardWithdrawal = () => {
  const navigate = useNavigateWithUrl();

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="start"
            align="start"
            flexShrink={0}
            direction="row"
            minHeight="65px"
          >
            <img
              src={BrainSuite}
              alt="brainHealth"
              className={`image--icon image--brain_health ${"image--icon--disabled"}`}
            />
            <LayoutBox
              gap="1/2x"
              justify="center"
              align="start"
              flexShrink={0}
              direction="column"
            >
              <Text bold size="xl">
                MRI検査+BrainSuite®
              </Text>
              <Text size="large">MRI検査+AI解析</Text>
            </LayoutBox>
          </LayoutBox>
        </>
      }
      onClick={() => navigate(PATIENT.USC026)}
    />
  );
};
export default MriCardWithdrawal;
