import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import {
  getAggregateUsa031,
  getMongoDb,
  getAggregateUsc024,
  getAggregateUsc025,
  getAggregateUsc021AllOrderYear,
  getAggregateUsc021AllOrderYearModality,
  getAggregateUsc021AllOrderYearBrain,
  getAggregateUsa022,
} from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { extractUniqueYearsFromData } from "../../../utils/utils";
import useForceUpdate from "../../common/useForceUpdate";

type YearType = {
  value: string;
  label: string;
};

type SettlementTimeType = {
  finished_at: Date;
};

const useUsc021DBActions = (orderYear: { orderYear: string }) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [listResult, setListResult] = useState<USC001Type[]>([]);
  const [brainListResult, setBrainListResult] = useState<USC021Type[]>([]);
  const [mriListResult, setMriListResult] = useState<USC002Type[]>([]);
  const [petListResult, setPetListResult] = useState<USC003Type[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [selectOrderYear, setSelectOrderYear] = useState<YearType[]>([
    { value: "すべて", label: "すべて" },
  ]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  // のうKnow取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_NOUKNOWS,
        );
        const nouknowAggregate = getAggregateUsa031(
          currentUser?.id ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await mongoDbPatients.aggregate(
          nouknowAggregate,
        )) as USC001Type[];
        setListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, orderYear.orderYear]);

  // 電話認知機能取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_BRAIN_CHECKS,
        );
        const brainAggregate = getAggregateUsa022(
          currentUser?.id ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await mongoDbPatients.aggregate(
          brainAggregate,
        )) as USC021Type[];
        setBrainListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, orderYear.orderYear]);

  // MRI取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const mriAggregate = getAggregateUsc024(
          currentUser?.id ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await mongoDbPatients.aggregate(
          mriAggregate,
        )) as USC002Type[];
        setMriListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, orderYear.orderYear]);

  // PET取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const petAggregate = getAggregateUsc025(
          currentUser?.id ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await mongoDbPatients.aggregate(
          petAggregate,
        )) as USC003Type[];
        setPetListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, orderYear.orderYear]);

  // 受検した年のセレクトボックス
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // のうKnow
        const mongoDb = getMongoDb(currentUser, Collection.PATIENT_NOUKNOWS);
        const aggregate = getAggregateUsc021AllOrderYear(currentUser?.id ?? "");
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as SettlementTimeType[];

        // 電話認知機能検査
        const mongoDbBrain = getMongoDb(
          currentUser,
          Collection.PATIENT_BRAIN_CHECKS,
        );
        const aggregateBrain = getAggregateUsc021AllOrderYearBrain(
          currentUser?.id ?? "",
        );
        const aggregateResultBrain = (await mongoDbBrain.aggregate(
          aggregateBrain,
        )) as SettlementTimeType[];

        // PET・MRI
        const mongoDbModality = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const aggregateModality = getAggregateUsc021AllOrderYearModality(
          currentUser?.id ?? "",
        );
        const aggregateResultModality = (await mongoDbModality.aggregate(
          aggregateModality,
        )) as SettlementTimeType[];
        const lists = [
          ...aggregateResult,
          ...aggregateResultModality,
          ...aggregateResultBrain,
        ];
        setSelectOrderYear(() => {
          const uniqueYears = extractUniqueYearsFromData({
            key: "finished_at",
            data: lists,
          });

          return [{ value: "すべて", label: "すべて" }, ...uniqueYears];
        });
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser]);

  return {
    error,
    listResult,
    brainListResult,
    selectOrderYear,
    fetchError,
    mriListResult,
    petListResult,
  };
};

export default useUsc021DBActions;
