import React, { useCallback } from "react";
import HeaderUser from "../components/Header/HeaderUser";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { isActiveUser } from "../utils/utils";
import { useRealmApp } from "../contexts/RealmApp";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import SpButton from "../components/Button/SpButton";
import Divider from "../components/Divider/Divider";
import {
  BRAIN_CHECKS_PHONE_NUMBER,
  USC_021_TAB_ITEMS,
} from "../constants/common";
import BrainHealth from "../assets/brain_health.png";
import Icon from "../components/Icon/Icon";
import useDeviceType from "../hooks/common/useDeviceType";
import useBrainCheckEligibility from "../hooks/common/useBrainCheckEligibility";
import usePath from "../hooks/common/usePath";

const USC023 = () => {
  const realmAppContext = useRealmApp();
  const navigate = useNavigateWithUrl();
  const isActive = isActiveUser(realmAppContext.currentUser);
  const [selectedButton, setSelectedId] = React.useState<string | number>("1");
  const [isExpanded, setIsExpanded] = React.useState(false);
  const isMobile = useDeviceType();
  const { isBrainCheckEligible } = useBrainCheckEligibility();
  const [path] = usePath();

  // Top画面に遷移
  const handleClickBack = useCallback(() => navigate(path), [navigate, path]);

  // タブの切り替え
  const handleTabChange = useCallback(
    (selected: string | number) => {
      setSelectedId(selected);
      if (selected === "2") navigate(PATIENT.USC026);
    },
    [navigate],
  );

  const handleCallPhoneNumber = () => {
    const phoneLink = `tel:${BRAIN_CHECKS_PHONE_NUMBER}`;

    // ダミーアンカータグを作成してクリックイベントを発火
    const a = document.createElement("a");
    a.href = phoneLink;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="検査"
          content="各種検査の受検と結果の確認ができます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <ToggleButton
                size="large"
                width="100%"
                items={USC_021_TAB_ITEMS.slice(0, 2)}
                onClick={handleTabChange}
                selectedButton={selectedButton}
              />
            </LayoutBox>
            <Title
              type="h2"
              header="電話認知機能検査"
              borderBottom="primaryLight"
              withButton={false}
            />
          </LayoutBox>
        </Sheet>
        <LayoutBox direction="column" gap="3x" fullWidth>
          <Sheet>
            <Sheet type="card" maxWidth="1064px">
              <LayoutBox direction="column" fullWidth align="center" gap="3x">
                <LayoutBox direction="column" fullWidth align="start" gap="2x">
                  <LayoutBox direction="row" align="center" gap="2x" fullWidth>
                    <img
                      src={BrainHealth}
                      alt="brainHealth"
                      className="image--brain_health"
                    />
                    <Text size="xl">あたまの健康チェック®</Text>
                  </LayoutBox>
                  <Divider darkColor orange={false} />
                  <Text size="xl">年に1回受検してください。</Text>
                  <Text size="xl">
                    自宅にいながら、電話で、短時間・高精度の認知機能チェックが受けられます。希望に応じて認知症等に関するご相談にも対応します。
                  </Text>
                  <Text size="xl">
                    ※サービスをご利用いただく際には、「お名前とPIN番号」をお聞きしますのでお手元にご用意ください。
                    PIN番号はチャット相談でご案内いたします。
                  </Text>
                  {!isMobile && isBrainCheckEligible && (
                    <Sheet
                      padding="16px"
                      type="gray"
                      maxHeight="300px"
                      overflow
                    >
                      <LayoutBox
                        gap="2x"
                        align="center"
                        justify="center"
                        width="100%"
                      >
                        <Icon size="small" icon="phone" />
                        <Text bold size="large">
                          {BRAIN_CHECKS_PHONE_NUMBER}
                        </Text>
                      </LayoutBox>
                    </Sheet>
                  )}
                  <Text bold size="xl">
                    受付時間
                    <br />
                    月曜～土曜9:00～17:00
                    <br />
                    （日曜・祝日・12/31～1/3を除く）
                  </Text>
                  {isExpanded && (
                    <Text size="base">
                      【ご利用に際して】
                      <br />
                      本サービスは、医療行為および診察行為を行うものではなく、本テストを通じて、ご利用者の認知機能の経時変化を確認できる簡易チェックテストの提供や受検済者本人の認知症に関するアドバイス等を提供するものです。認知機能の一般的な評価を提供することを目的とし、認知機能に関与し得る病状または病気を単独で診断、治療、予防することを目的としたものではありません。
                    </Text>
                  )}
                </LayoutBox>
                <SpButton
                  type="secondary"
                  size="medium"
                  icon={
                    isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"
                  }
                  onClick={() => setIsExpanded(!isExpanded)}
                  iconSize="large"
                >
                  {isExpanded ? "閉じる" : "すべて表示"}
                </SpButton>
                {isMobile && (
                  <SpButton
                    size="medium"
                    icon="phone"
                    mobileFullWidth
                    withArrowRight
                    onClick={handleCallPhoneNumber}
                    disabled={!isBrainCheckEligible}
                  >
                    受検する
                  </SpButton>
                )}
              </LayoutBox>
            </Sheet>
          </Sheet>
        </LayoutBox>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC023;
