import React from "react";
import Title from "../Title/Title";
import LayoutBox from "../LayoutBox/LayoutBox";
import Sheet from "../Sheet/Sheet";
import CheckboxGroup from "./CheckboxGroup";
import { setPolicyChecks } from "../../hooks/common/usePolicyChecks";
import Link from "../Link/Link";
import Text from "../Text/Text";

type TermsOfServiceCheckBoxProps = {
  checkedItems: {
    label: JSX.Element;
    checked: boolean;
    value: string;
  }[];
  setCheckedItems: React.Dispatch<
    React.SetStateAction<
      {
        label: JSX.Element;
        checked: boolean;
        value: string;
      }[]
    >
  >;
};

export const termsOfServiceCheckBoxItems = [
  {
    label: (
      <>
        <Link color="primary">利用規約</Link>
        <Text size="xl">に同意する</Text>
      </>
    ),
    checked: false,
    value: "1",
  },
  {
    label: (
      <>
        <Link color="primary">個人情報の取扱いについて</Link>
        <Text size="xl">に同意する</Text>
      </>
    ),
    checked: false,
    value: "2",
  },
];

const TermsOfServiceCheckBox: React.FC<TermsOfServiceCheckBoxProps> = ({
  checkedItems,
  setCheckedItems,
}) => {
  const handleCheckboxChange = (value: string) => {
    const updatedCheckboxItems = checkedItems.map((item) =>
      item.value === value ? { ...item, checked: !item.checked } : item,
    );
    setCheckedItems(updatedCheckboxItems);
    setPolicyChecks(updatedCheckboxItems.map((item) => item.checked));
  };

  return (
    <Sheet type="card" padding="16px 16px 32px 16px">
      <LayoutBox direction="column" fullWidth gap="3x">
        <Title
          type="h2"
          header="利用規約・個人情報の取扱いについて"
          withButton={false}
          borderBottom="primaryLight"
        />
        <CheckboxGroup
          items={checkedItems}
          withContainer
          onChecked={handleCheckboxChange}
        />
      </LayoutBox>
    </Sheet>
  );
};
export default TermsOfServiceCheckBox;
